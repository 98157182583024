import {Divider, Image, Menu,} from 'semantic-ui-react'
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {isNotInsuranceContract, isRoleBrokerAdmin} from "../../utils/authorities-utils";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function MenuSideBar() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState("");


    useEffect(() => {
        setActiveMenu(location.pathname);
    }, [location]);

    const menuChange = (path) => {
        navigate(path);
    }

    return (
        <>

            <Menu pointing secondary vertical fluid className="menu2">

                <Image onClick={() => navigate("/")} src={"https://asset-fr.trepidai.pro/save/logo/LogoSaveBlanc.svg"}  centered
                       style={{marginTop: '7px', marginBottom: '7px'}}/>

                <Menu.Item className="menuItem" name='accueil'
                           onClick={() => menuChange('/')}
                           active={activeMenu === '/'}>
                    {t('menu.home')}
                </Menu.Item>

                <Menu.Item className="menuItem" name='create'
                           onClick={() => menuChange('/create')}
                           active={activeMenu.includes('/create')}>
                    {t('menu.intervention')}
                </Menu.Item>

                <Menu.Item className="menuItem" name='followup'
                           onClick={() => menuChange('/followup')}
                           active={activeMenu.includes('/followup')}>
                    {t('menu.followup')}
                </Menu.Item>

                {isRoleBrokerAdmin(user) && isNotInsuranceContract(user) && user.broker.hgBroker && <Menu.Item className="menuItem" name='pricing'
                           onClick={() => menuChange('/pricing')}
                           active={activeMenu.includes('/pricing')}>
                    {t('menu.pricing')}
                </Menu.Item>}

                {/* TODO : Colisage */}
                {/*<Menu.Item className="menuItem" name='shipping'*/}
                {/*           onClick={() => menuChange('/shipping')}*/}
                {/*           active={activeMenu.includes('/shipping')}>*/}
                {/*    Colisage*/}
                {/*</Menu.Item>*/}

                {/* TODO : Facturation page */}
                {/*<Menu.Item className="menuItem" name='billing'*/}
                {/*           onClick={() => menuChange('/billing')}*/}
                {/*           active={activeMenu.includes('/billing')}>*/}
                {/*    Facturation*/}
                {/*</Menu.Item>*/}

                <Divider/>
                {/* TODO : Add broker param to show/hide this menu */}
                {/*<Menu.Item className="menuItem" name='fleet'*/}
                {/*           onClick={() => menuChange('/fleets')}*/}
                {/*           active={activeMenu.includes('/fleets')}>*/}
                {/*    Gestion de flotte*/}
                {/*</Menu.Item>*/}
                {/*<Divider/>*/}

                {/*<Menu.Item className="menuItem" name='settings'*/}
                {/*           onClick={() => menuChange('/settings')}*/}
                {/*           active={activeMenu.includes('/settings')}>*/}
                {/*    Paramètrage*/}
                {/*</Menu.Item>*/}

                <Menu.Item className="menuItem" name='account'
                           onClick={() => menuChange('/account')}
                           active={activeMenu.includes('/account')}>
                    {t('menu.account')}
                </Menu.Item>

                {isRoleBrokerAdmin(user) &&
                    <Menu.Item className="menuItem" name='users'
                               onClick={() => menuChange('/users')}
                               active={activeMenu.includes('/users')}>
                        {t('menu.users')}
                    </Menu.Item>
                }

                {isRoleBrokerAdmin(user) &&
                    <Menu.Item className="menuItem" name='partner'
                               onClick={() => menuChange('/partner')}
                               active={activeMenu.includes('/partner')}>
                        {t('menu.partner')}
                    </Menu.Item>
                }

            </Menu>
        </>

    );
}


export default MenuSideBar;
