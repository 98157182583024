import { Dropdown, Flag, Icon, Menu } from 'semantic-ui-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../reducers/User/user.actions';
import BrokerSelectionModal from '../BrokerSelectionModal/BrokerSelectionModal';
import { isRoleSaveAdmin } from '../../utils/authorities-utils';
import i18n from "i18next";
import {useTranslation} from "react-i18next";

function HeaderApp(props) {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [openModal, setOpenModal] = useState(false);

    const exit = () => {
        dispatch(logout());
        navigate('/');
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <>
            <Menu
                fluid={true}
                borderless={true}
                style={{
                    marginBottom: '30px',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    boxShadow: 'none',
                }}
            >
                <Menu.Item onClick={props.toggleSidebar}>
                    <Icon name="bars" />
                </Menu.Item>
                <Menu.Item
                    onClick={() => setOpenModal(true)}
                    style={{
                        fontSize: 'x-large',
                        padding: '0px',
                        marginLeft: '-10px',
                        fontWeight: '600',
                        fontFamily: 'GothamRounded',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <span>{user.broker.name}</span>
                    {user.broker.imgUrl && (
                        <>
              <span
                  style={{
                      width: '5px',
                      height: '5px',
                      backgroundColor: '#00BFB2',
                      borderRadius: '50%',
                      marginLeft: '10px',
                      marginRight: '10px',
                  }}
              ></span>
                            <img
                                className="mix-blend-mode"
                                alt="logo"
                                src={user.broker.imgUrl}
                                style={{ maxHeight: '30px', width: 'auto' }}
                            />
                        </>
                    )}
                </Menu.Item>
                <Menu.Menu position="right">
                    <Dropdown item text={user.login}>
                        <Dropdown.Menu>
                            <Dropdown.Header icon="user" content={user.firstName + ' ' + user.lastName} />
                            <Dropdown.Divider />
                            <Dropdown.Item>
                                {' '}
                                <a onClick={() => changeLanguage('fr')}>
                                    <Flag name="fr"/>
                                    Français
                                </a>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                {' '}
                                <a onClick={() => changeLanguage('es')}>
                                    <Flag name="es"/>
                                    Español
                                </a>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                {' '}
                                <a onClick={() => changeLanguage('pl')}>
                                    <Flag name="pl"/>
                                    Polski
                                </a>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                {' '}
                                <a onClick={() => changeLanguage('en')}>
                                    <Flag name="uk"/>
                                    English
                                </a>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => navigate('/account')}>
                                {' '}
                                <Icon name="setting" />
                                {t('header.manage-account')}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={exit}>
                                {' '}
                                <Icon name="log out" />
                                {t('header.disconnect')}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            </Menu>

            {isRoleSaveAdmin(user) && <BrokerSelectionModal openModal={openModal} setOpenModal={setOpenModal} />}
        </>
    );
}

export default HeaderApp;
